<template>
    <div class =" text-justify col-span-12 mr-4 ml-2 mt-4 rounded-lg bg-TFL-cardDashboard  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg col-span-2 lg:col-span-1 shadow-[rgba(0,_0,_0,_0.10)_0px_25px_50px_-12px]">
        <p class="text-TFL-dashText1"> ایجاد چالش جدید</p>
        <p class="text-sm mt-4 text-gray-300 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>کاربر گرامی در این قسمت می‌توانید نسبت به خرید و ایجاد چالش جدید اقدام نمایید.</p>
        <p class="text-sm mt-1 text-gray-300 flex  mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>پس از تکمیل خرید، حساب به صورت خودکار به لیست حساب‌ها اضافه و ایمیل خواهد شد.</p>
        <p class="text-sm mt-1 text-gray-300 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg> پیش از خرید از صحت حساب انتخابی مطمئن شده و پس از مطالعه قوانین اقدام نمایید.</p>
        <p class="text-sm mt-1 text-gray-300 flex  mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>پس از ورود به درگاه پرداخت و انتقال وجه، تا زمان تایید نهایی منتظر بمانید و از صفحه مرورگر خود خارج نشوید.</p>
        <p class="text-sm mt-1 text-gray-300 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg> پرداخت از طریق درگاه کریپتو، تنها از طریق لایت‌کوین و شبکه پالیگان میسر می‌باشد.</p>
        <p class="text-sm mt-1 text-gray-300 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>در صورت تمایل برای پرداخت از طریق تتر بر روی شبکه ترون، با پشتیبانی تلگرام در ارتباط باشید.</p>
        <p class="text-sm mt-1 text-TFL-red1 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg> در صورت استفاده از صرافی جهت پرداخت، کارمزد انتقال صرافی را در نظر گرفته و بر روی مبلغ نهایی لحاظ بفرمایید. به دلیل ایجاد حساب به صورت خودکار، در صورت عدم تائید و ناکافی بودن مبلغ پرداختی، حساب ایجاد نخواهد شد.</p>
        
    </div>
    <!-- <div class =" text-justify col-span-12 mr-4 ml-2 mt-4 rounded-lg bg-TFL-red1 bg-opacity-60  p-4  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg col-span-2 lg:col-span-1 shadow-[rgba(0,_0,_0,_0.10)_0px_25px_50px_-12px]">
        <p class="text-gray-300">با سلام و احترام</p>
        <p class="text-gray-300">به دلیل برخی مشکلات از سمت درگاه آنلاین زرین‌پال در حال حاضر امکان پرداخت وجود ندارد.</p>
        <p class="text-gray-300">در صورتی که تمایل دارید به صورت ریالی پرداخت کنید، مراحل ثبت‌نام را در سایت پیش بروید و قیمت نهایی نمایش داده شده را از طریق لینک زیر پرداخت نمایید:</p>
        <p class="text-gray-300">https://zarinp.al/zarin.express/tfl</p>
        <p class="text-gray-300">سپس تصویر فیش تراکنش به همراه اطلاعات زیر را به پشتیبانی تلگرام https://t.me/TFLFund_Support ارسال نمایید:</p>
        <p class="text-gray-300">- ایمیل حساب کاربری</p>
        <p class="text-gray-300">- بروکر درخواستی</p>
        <p class="text-gray-300">- نوع پلن</p>
        <p class="text-gray-300">- بالانس حساب</p>
        <p class="text-gray-300">مجددا برای غیر فعال بودن درگاه آنلاین پوزش می‌طلبیم و در تلاش هستیم به سرعت این مشکل برطرف شود.</p>
        
        
    </div> -->
   <div class="grid grid-rows-4 grid-cols-12 w-full mt-2 text-sm md:text-base ">
       
         
            <div v-if="plan !== 'Rapid' && plan !== 'Multiple'" class="col-span-12 lg:col-span-7 row-span-1 text-sm  order-2 bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
                <div class="grid grid-cols-3 text-sm   p-1  shadow-card rounded-lg ">
                    
                    <div>
                        <label class="block text-TFL-dashText1 text-center font-iranSans" >
                            قوانین پلن 
                        </label>
                        
                    </div>
                    <div>
                        <label class="block text-TFL-dashText1 text-center font-iranSans" >
                            فاز اول 
                        </label>
                        
                    </div>
                    <div>
                        <label class="block text-TFL-dashText1 text-center font-iranSans" >
                            فاز دوم 
                        </label>
                        
                    </div>
                    
                    
                </div>
                <div class="grid grid-cols-3 bg-gray-600 bg-opacity-30  p-1  shadow-card rounded-lg">
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                        مدت زمان (روز) 
                        </label>
                        
                    </div>
                   
                    <div>
                        <label v-if="this.plan == 'Limited' || this.plan == 'Limited6'" class="block text-TFL-dashText2 text-center font-iranSans" >
                            {{ formatNumber(30) }}  
                        </label>
                        <label v-if="this.plan == 'NoLimit' || this.plan == 'NoLimit6'" class="block text-TFL-dashText2 text-center font-iranSans" >
                            بدون محدودیت  
                        </label>
                        
                    </div>
                    <div>
                        <label v-if="this.plan == 'Limited' || this.plan == 'Limited6' " class="block text-TFL-dashText2 text-center font-iranSans" >
                             {{ formatNumber(60) }} 
                        </label>
                        <label v-if="this.plan == 'NoLimit' || this.plan == 'NoLimit6'" class="block text-TFL-dashText2 text-center font-iranSans" >
                            بدون محدودیت
                        </label>
                        
                    </div>

   
                </div>
                <div class="grid grid-cols-3   p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         هدف سود 
                        </label>
                        
                    </div>
                    <div>
                        <label v-if="deposit === 'K10' && (this.plan == 'NoLimit' || this.plan == 'Limited')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(800) }} %{{ formatNumber(8) }}
                        </label>
                        <label v-if="deposit === 'K10' && (this.plan == 'NoLimit6' || this.plan == 'Limited6')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(600) }} %{{ formatNumber(6) }}
                        </label>
                        <label v-if="deposit === 'K5' && (this.plan == 'NoLimit' || this.plan == 'Limited')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(400) }} %{{ formatNumber(8) }} 
                        </label>
                        <label v-if="deposit === 'K5' && (this.plan == 'NoLimit6' || this.plan == 'Limited6')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(300) }} %{{ formatNumber(6) }} 
                        </label>
                        <label v-if="deposit === 'K1' && (this.plan == 'NoLimit' || this.plan == 'Limited')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(80) }} %{{ formatNumber(8) }}
                        </label>
                        <label v-if="deposit === 'K1' && (this.plan == 'NoLimit6' || this.plan == 'Limited6')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(60) }} %{{ formatNumber(6) }}
                        </label>
                        <label v-if="deposit === 'K25' && (this.plan == 'NoLimit' || this.plan == 'Limited')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(2000) }} %{{ formatNumber(8) }}
                        </label>
                        <label v-if="deposit === 'K25' && (this.plan == 'NoLimit6' || this.plan == 'Limited6')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(1500) }} %{{ formatNumber(6) }}
                        </label>
                        
                    </div>
                    <div>
                        <label v-if="deposit === 'K10' && (this.plan == 'NoLimit' || this.plan == 'Limited')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(400) }} %{{ formatNumber(4) }}
                        </label>
                        <label v-if="deposit === 'K10' && (this.plan == 'NoLimit6' || this.plan == 'Limited6')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(600) }} %{{ formatNumber(6) }}
                        </label>
                        <label v-if="deposit === 'K5' && (this.plan == 'NoLimit' || this.plan == 'Limited')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(200) }} %{{ formatNumber(4) }} 
                        </label>
                        <label v-if="deposit === 'K5' && (this.plan == 'NoLimit6' || this.plan == 'Limited6')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(300) }} %{{ formatNumber(6) }} 
                        </label>
                        <label v-if="deposit === 'K1' && (this.plan == 'NoLimit' || this.plan == 'Limited')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(40) }} %{{ formatNumber(4) }}
                        </label>
                        <label v-if="deposit === 'K1' && (this.plan == 'NoLimit6' || this.plan == 'Limited6')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(60) }} %{{ formatNumber(6) }}
                        </label>
                        
                        <label v-if="deposit === 'K25' && (this.plan == 'NoLimit' || this.plan == 'Limited')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(1000) }} %{{ formatNumber(4) }}
                        </label>
                        <label v-if="deposit === 'K25' && (this.plan == 'NoLimit6' || this.plan == 'Limited6')" class="block text-TFL-green1 text-center font-iranSans" >
                            ${{ formatNumber(1500) }} %{{ formatNumber(6) }}
                        </label>
                        
                    </div>
   
                </div>
                <div class="grid grid-cols-3 bg-gray-600 bg-opacity-30  p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         حداکثر ریسک شناور   
                        </label>
                        
                    </div>
                    <div>
                        <label v-if="deposit === 'K5'" class="block text-TFL-red1 text-center font-iranSans" >
                           -
                        </label>
                        <label v-if="deposit === 'K10'" class="block text-TFL-red1 text-center font-iranSans" >
                           -
                        </label>
                        <label v-if="deposit === 'K1'" class="block text-TFL-red1 text-center font-iranSans" >
                           -
                        </label>
                        <label v-if="deposit === 'K25'" class="block text-TFL-red1 text-center font-iranSans" >
                           -
                        </label>
                        
                    </div>
                    <div>
                        <label v-if="deposit === 'K5'" class="block text-TFL-red1 text-center font-iranSans" >
                           -
                        </label>
                        <label v-if="deposit === 'K10'" class="block text-TFL-red1 text-center font-iranSans" >
                           -
                        </label>
                        <label v-if="deposit === 'K1'" class="block text-TFL-red1 text-center font-iranSans" >
                           -
                        </label>
                        <label v-if="deposit === 'K25'" class="block text-TFL-red1 text-center font-iranSans" >
                           -
                        </label>
                        
                    </div>

   
                </div>
                <div class="grid grid-cols-3   p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         حد ضرر روزانه 
                        </label>
                        
                    </div>
                    <div>
                        <label v-if="deposit === 'K5'" class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        <label v-if="deposit === 'K10'" class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        <label v-if="deposit === 'K1'" class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        <label v-if="deposit === 'K25'" class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        
                    </div>
                    <div>
                        <label v-if="deposit === 'K5'" class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        <label v-if="deposit === 'K10'" class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        <label v-if="deposit === 'K1'" class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        <label v-if="deposit === 'K25'" class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        
                    </div>

   
                </div>
                <div class="grid grid-cols-3 bg-gray-600 bg-opacity-30  p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         حد ضرر کلی  
                        </label>
                        
                    </div>
                    <div>
                        <label v-if="deposit === 'K5'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(600) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K10'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(1200) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K1'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(120) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K25'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(3000) }} %{{ formatNumber(12) }}
                        </label>
                        
                    </div>
                    <div>
                        <label v-if="deposit === 'K5'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(600) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K10'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(1200) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K1'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(120) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K25'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(3000) }} %{{ formatNumber(12) }}
                        </label>
                        
                    </div>
                    

   
                </div>
            </div>
            <div v-if="plan === 'Rapid'" class="col-span-12 lg:col-span-7 row-span-1 text-sm  order-2 bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
                <div class="grid grid-cols-3 text-sm   p-1  shadow-card rounded-lg ">
                    
                    <div>
                        <label class="block text-TFL-dashText1 text-center font-iranSans" >
                            قوانین پلن 
                        </label>
                        
                    </div>
                    <div class="col-span-2">
                        <label class="block text-TFL-dashText1 text-center font-iranSans" >
                            مقدار  
                        </label>
                        
                    </div>
                   
                    
                    
                </div>
                <div class="grid grid-cols-3 bg-gray-600 bg-opacity-30  p-1  shadow-card rounded-lg">
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                        مدت زمان (روز) 
                        </label>
                        
                    </div>
                   
                    <div class="col-span-2">
                        
                        <label  class="block text-TFL-dashText2 text-center font-iranSans" >
                            بدون محدودیت  
                        </label>
                        
                    </div>
                    

   
                </div>
                <div class="grid grid-cols-3   p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         هدف سود 
                        </label>
                        
                    </div>
                    <div class="col-span-2">
                        <label  class="block text-TFL-green1 text-center font-iranSans" >
                            -
                        </label>
                        
                        
                    </div>
                    

   
                </div>
                <div class="grid grid-cols-3 bg-gray-600 bg-opacity-30  p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         حداکثر ریسک شناور 
                        </label>
                        
                    </div>
                    <div class="col-span-2">
                        <label  class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(2) }}%
                        </label>
                        
                        
                    </div>
                    

   
                </div>
                <div class="grid grid-cols-3   p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         حد ضرر روزانه 
                        </label>
                        
                    </div>
                    <div class="col-span-2">
                        <label  class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        
                        
                    </div>
                    

   
                </div>
                <div class="grid grid-cols-3 bg-gray-600 bg-opacity-30  p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         حد ضرر کلی  
                        </label>
                        
                    </div>
                    <div class="col-span-2">
                        <label v-if="deposit === 'K5'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(600) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K10'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(1200) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K1'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(120) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K100'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(12000) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K25'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(3000) }} %{{ formatNumber(12) }}
                        </label>
                        <label v-if="deposit === 'K50'" class="block text-TFL-red1 text-center font-iranSans" >
                            ${{ formatNumber(6000) }} %{{ formatNumber(12) }}
                        </label>
                        
                    </div>
                    
                    

   
                </div>
            </div>
            <div v-if="plan === 'Multiple'" class="col-span-12 lg:col-span-7 row-span-1 text-sm  order-2 bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
                <div class="grid grid-cols-3 text-sm   p-1  shadow-card rounded-lg ">
                    
                    <div>
                        <label class="block text-TFL-dashText1 text-center font-iranSans" >
                            قوانین پلن 
                        </label>
                        
                    </div>
                    <div class="col-span-2">
                        <label class="block text-TFL-dashText1 text-center font-iranSans" >
                            مقدار  
                        </label>
                        
                    </div>
                   
                    
                    
                </div>
                <div class="grid grid-cols-3 bg-gray-600 bg-opacity-30  p-1  shadow-card rounded-lg">
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                        مدت زمان (روز) 
                        </label>
                        
                    </div>
                   
                    <div class="col-span-2">
                        
                        <label  class="block text-TFL-dashText2 text-center font-iranSans" >
                            بدون محدودیت  
                        </label>
                        
                    </div>
                    

   
                </div>
                <div class="grid grid-cols-3   p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         هدف سود 
                        </label>
                        
                    </div>
                    <div class="col-span-2">
                        <label  class="block text-TFL-green1 text-center font-iranSans" >
                            %{{ formatNumber(10) }}
                        </label>
                        
                        
                    </div>
                    

   
                </div>
                <div class="grid grid-cols-3 bg-gray-600 bg-opacity-30  p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         حداکثر ریسک شناور 
                        </label>
                        
                    </div>
                    <div class="col-span-2">
                        <label  class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(2) }}%
                        </label>
                        
                        
                    </div>
                    

   
                </div>
                <div class="grid grid-cols-3   p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         حد ضرر روزانه 
                        </label>
                        
                    </div>
                    <div class="col-span-2">
                        <label  class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        
                        
                    </div>
                    

   
                </div>
                <div class="grid grid-cols-3 bg-gray-600 bg-opacity-30  p-1  shadow-card rounded-lg">
                   
                    <div>
                        <label class="block text-TFL-dashText2 text-center font-iranSans" >
                         حد ضرر کلی  
                        </label>
                        
                    </div>
                    <div class="col-span-2">
                        <label  class="block text-TFL-red1 text-center font-iranSans" >
                            {{ formatNumber(5) }}%
                        </label>
                        
                    </div>
                    
                    

   
                </div>
            </div>
         
         
       


            <div class="grid grid-cols-12 row-span-4 text-sm  col-span-12 lg:col-span-5 col-row-5 mr-4 flex bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
                <div class="col-span-12  ">
                    <label class="block text-TFL-dashText1  text-right font-iranSans" >
                       نوع پلن
                    </label>
                    <div class="grid grid-cols-12 text-xs" >
                        <button @click="choosePlan('Limited')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.plan !== 'Limited','bg-TFL-base1  text-white': this.plan === 'Limited' }" class="col-span-12 text-sm inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">چالش دارای محدودیت زمانی و تارگت سود {{ formatNumber(8) }} و {{ formatNumber(4) }}</button>
                        <button @click="choosePlan('Limited6')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.plan !== 'Limited6','bg-TFL-base1  text-white': this.plan === 'Limited6' }" class="col-span-12 text-sm inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">چالش دارای محدودیت زمانی و تارگت سود {{ formatNumber(6) }} و {{ formatNumber(6) }}</button>
                        <button @click="choosePlan('NoLimit')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.plan !== 'NoLimit','bg-TFL-base1  text-white': this.plan === 'NoLimit' }" class="col-span-12 text-sm py-2 px-4 m-1 rounded-lg">چالش بدون محدودیت زمانی و تارگت سود {{ formatNumber(8) }} و {{ formatNumber(4) }}</button>
                        <button @click="choosePlan('NoLimit6')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.plan !== 'NoLimit6','bg-TFL-base1  text-white': this.plan === 'NoLimit6' }" class="col-span-12 text-sm py-2 px-4 m-1 rounded-lg">چالش بدون محدودیت زمانی و تارگت سود {{ formatNumber(6) }} و {{ formatNumber(6) }}</button>
                        <button @click="choosePlan('Rapid')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.plan !== 'Rapid','bg-TFL-base1  text-white': this.plan === 'Rapid' }" class="col-span-12 text-sm inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">پلن مستقیم  </button>
                        <button @click="choosePlan('Multiple')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.plan !== 'Multiple','bg-TFL-base1  text-white': this.plan === 'Multiple' }" class="col-span-12 text-sm inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">پلن 5X  </button>
                        
                        
                    </div>

                    
                </div>
                <div class="col-span-12 mt-3 ">
                    <label v-if="plan !== 'Multiple'" class="block text-TFL-dashText1  text-right font-iranSans" >
                       بالانس حساب
                    </label>
                    <label v-if="plan === 'Multiple'" class="block text-TFL-dashText1  text-right font-iranSans" >
                        میزان جایزه
                    </label>
                    <div v-if="plan !== 'Multiple'" class="grid grid-cols-12" >
                        <button @click="depositButtonClick('K1')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K1','bg-TFL-base1  text-white': this.deposit === 'K1' }" class="col-span-4 inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">{{ formatNumber(1000) }} </button>
                        <button @click="depositButtonClick('K5')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K5','bg-TFL-base1  text-white': this.deposit === 'K5' }" class="col-span-4 py-2 px-4 m-1 rounded-lg">{{ formatNumber(5000) }}</button>
                        <button @click="depositButtonClick('K10')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K10','bg-TFL-base1  text-white': this.deposit === 'K10' }" class="col-span-4 py-2 px-4 m-1 rounded-lg">{{ formatNumber(10000) }}</button>
                        <button @click="depositButtonClick('K25')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K25','bg-TFL-base1  text-white': this.deposit === 'K25' }"  class="col-span-4 py-2 px-4 m-1 rounded-lg">{{ formatNumber(25000) }}</button>
                        <button @click="depositButtonClick('K50')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K50','bg-TFL-base1  text-white': this.deposit === 'K50' }" :disabled="this.plan !== 'Rapid'" class="col-span-4 py-2 px-4 m-1 rounded-lg">{{ formatNumber(50000) }}</button>
                        <button @click="depositButtonClick('K100')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K100','bg-TFL-base1  text-white': this.deposit === 'K100' }" :disabled="this.plan !== 'Rapid'" class="col-span-4 py-2 px-4 m-1 rounded-lg">{{ formatNumber(100000) }}</button>
                        
                    </div>
                    <div v-if="plan === 'Multiple'" class="grid grid-cols-12" >
                        <button @click="depositButtonClick('K50')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K50','bg-TFL-base1  text-white': this.deposit === 'K50' }" class="col-span-4 inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">جایزه {{ formatNumber(125) }}$ </button>
                        <button @click="depositButtonClick('K100')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K100','bg-TFL-base1  text-white': this.deposit === 'K100' }" class="col-span-4 py-2 px-4 m-1 rounded-lg">جایزه {{ formatNumber(250) }}$</button>
                        <button @click="depositButtonClick('K10')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K10','bg-TFL-base1  text-white': this.deposit === 'K10' }" :disabled="this.plan === 'Multiple'" class="col-span-4 py-2 px-4 m-1 rounded-lg">-</button>
                        <button @click="depositButtonClick('K25')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K25','bg-TFL-base1  text-white': this.deposit === 'K25' }" :disabled="this.plan === 'Multiple'" class="col-span-4 py-2 px-4 m-1 rounded-lg">-</button>
                        <button @click="depositButtonClick('K5')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K1','bg-TFL-base1  text-white': this.deposit === 'K1' }" :disabled="this.plan === 'Multiple'" class="col-span-4 py-2 px-4 m-1 rounded-lg">-</button>
                        <button @click="depositButtonClick('K1')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.deposit !== 'K5','bg-TFL-base1  text-white': this.deposit === 'K5' }" :disabled="this.plan === 'Multiple'" class="col-span-4 py-2 px-4 m-1 rounded-lg">-</button>
                        
                    </div>
                    <p class="text-xs mt-1 text-gray-300 text-opacity-70 flex mx-2"><svg class="flex-shrink-0 w-4 h-4 text-TFL-base1 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                    </svg>حساب‌های چالش دو مرحله‌ای تا سقف {{ formatNumber(25000) }} دلار ارائه خواهند شد.</p>

                    
                </div>

                <div class="col-span-12 mt-3">
                    <label class="block text-TFL-dashText1 text-right font-iranSans" >
                       انتخاب بروکر 
                    </label>
                    <div class="grid grid-cols-12" >
                        <button @click="brokerButtonClick('Roboforex')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.broker !== 'Roboforex','bg-TFL-base1 text-white': this.broker === 'Roboforex' }" class="py-2 px-4 m-1 rounded-lg col-span-6 text-sm ">Roboforex</button>
                        <button @click="brokerButtonClick('Fxtm')" :class="{ 'bg-gray-600 bg-opacity-30  text-TFL-dashText2': this.broker !== 'Fxtm','bg-TFL-base1 text-white': this.broker === 'Fxtm' }" class=" py-2 px-4 col-span-6 text-sm rounded-lg m-1 ">Fxtm</button>
                        <button @click="brokerButtonClick('ICMarkets')" :class="{ 'bg-gray-600 bg-opacity-30  text-TFL-dashText2': this.broker !== 'ICMarkets','bg-TFL-base1 text-white': this.broker === 'ICMarkets' }" class=" py-2 px-4 col-span-6 text-sm rounded-lg m-1 ">ICMarkets</button>
                        <button @click="brokerButtonClick('Tickmill')" :class="{ 'bg-gray-600 bg-opacity-30  text-TFL-dashText2': this.broker !== 'Tickmill','bg-TFL-base1 text-white': this.broker === 'Tickmill' }" class=" py-2 px-4 col-span-6 text-sm rounded-lg m-1 ">Tickmill</button>
                        <button @click="brokerButtonClick('Alpari')" :class="{ 'bg-gray-600 bg-opacity-30  text-TFL-dashText2': this.broker !== 'Alpari','bg-TFL-base1 text-white': this.broker === 'Alpari' }" class=" py-2 px-4 col-span-6 text-sm rounded-lg m-1 ">Alpari</button>
                        <!-- <button @click="brokerButtonClick('Exness')" :class="{ 'bg-gray-600 bg-opacity-30  text-TFL-dashText2': this.broker !== 'Exness','bg-TFL-base1 text-white': this.broker === 'Exness' }" class=" py-2 px-4 col-span-6 text-sm rounded-lg m-1 ">Exness</button> -->
                                            
                    </div>

                    

                </div>

                <div class="col-span-12 mt-3">
                    <label class="block text-TFL-dashText1 text-right font-iranSans" >
                       انتخاب سرور 
                    </label>


                    <div class="relative mt-1 text-right">
                        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center px-2 text-gray-300">
                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                        </div>
                        <select v-model="serverName"   class="block text-right appearance-none w-full bg-gray-600 bg-opacity-30 border-2 border-TFL-base1 text-gray-300 py-2 px-4 pr-8 rounded-lg leading-tight focus:outline-none  focus:border-TFL-base1" id="grid-state">
                            <option class="bg-gray-700 text-gray-300"  v-for="(serverNameL, index) in serverNameList" :key="index" >{{serverNameL.name}}</option>
                        </select>
                    </div>
                    <div class="text-blue-500 mt-2  ">

                        <a href="/Brokers" target="_blank" >حساب‌های تست و مقایسه بروکرها</a>
                    </div>
                </div>
                <div class="col-span-12 mt-4  ">

                    <label class=" block text-TFL-dashText1  text-right font-iranSans" >
                           انتخاب درگاه پرداخت
                        </label>
                        <div class=" grid grid-cols-12" >
                            <button @click="choosePayment('Crypto')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Crypto','bg-TFL-base1  text-white': this.paymentType === 'Crypto' }" class="sm:col-span-6 col-span-12 text-sm inline-flex items-center justify-center py-2 px-4 m-1 rounded-lg">ارز دیجیتال </button>
                            <button v-if="loadToken" @click="choosePayment('Rial')" :disabled="notAllowRial" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Rial','bg-TFL-base1  text-white': this.paymentType === 'Rial' }" class="sm:col-span-6 col-span-12 text-sm py-2 px-4 m-1 rounded-lg">ریالی (زرین پال)</button>
                            <!-- <button v-if="loadToken" @click="choosePayment('Rial2')" :disabled="notAllowRial" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Rial2','bg-TFL-base1  text-white': this.paymentType === 'Rial2' }" class="sm:col-span-4 col-span-12 text-sm py-2 px-4 m-1 rounded-lg">ریالی (رایان پی)</button> -->
                            <!-- <button v-if="loadToken" @click="choosePayment('Rial2')" :disabled="notAllowRial" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Rial2','bg-TFL-base1  text-white': this.paymentType === 'Rial2' }" class="sm:col-span-4 col-span-12 text-sm py-2 px-4 m-1 rounded-lg">ریالی (رایان پی)</button> -->
                            <button v-if="!loadToken"  :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Rial','bg-TFL-base1  text-white': this.paymentType === 'Rial' }" class="col-span-6 text-sm py-2 px-4 m-1 rounded-lg">
                                <div role="status justify-self-center">
                                    <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                            <div   v-if="paymentType == 'Crypto'" class=  "bg-TFL-red1 order-3  text-white text-xs col-span-12 px-2  p-1 m-1  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">     
                                برای استفاده از درگاه  کریپتو از آیپی خارج از ایران استفاده نمایید.
                              </div>
                              <div   v-if="paymentType == 'Rial'" class=  "bg-TFL-red1 order-3  text-white text-xs col-span-12 px-2  p-1 m-1  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">     
                                برای استفاده از درگاه ریالی با آیپی ایران وارد شوید.
                              </div>
                              <div   v-if="notAllowRial && paymentType == 'Rial' " class=  "bg-TFL-red1 order-3  text-white text-xs col-span-12 px-2  p-1 m-1  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">     
                                برای استفاده از درگاه ریالی از قسمت "پروفایل" شماره همراه خود را تائید کنید.
                              </div>
                            <!-- <button v-if="!loadToken"  :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.paymentType !== 'Rial2','bg-TFL-base1  text-white': this.paymentType === 'Rial2' }" class="col-span-6 text-sm py-2 px-4 m-1 rounded-lg">
                                <div role="status justify-self-center">
                                    <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button> -->
                            
                            
                        </div>
                        <div   v-if="notAllowRial" class=  "bg-TFL-red1 order-3  text-white text-xs col-span-12 px-2  p-1 m-1  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">     
                                برای استفاده از درگاه ریالی از قسمت "پروفایل" شماره همراه خود را تائید کنید.
                              </div>
                </div>
                <div class="col-span-12 grid grid-cols-12 mt-3" >

                    <label class="col-span-12 block text-TFL-dashText1  text-right font-iranSans" >
                       کد تخفیف
                    </label>
                    <div class=" col-span-8 m-1 mx-1">
                   <input dir="ltr" v-model="discountCode"  type="text"  id="small-input" class="block w-full p-2 h-8  bg-gray-600 bg-opacity-30 text-gray-200  rounded-lg  sm:text-xs focus:ring-blue-500 focus:border-blue-500 ">
                 </div>  
                 <button v-if="LoadingResponseCode" :disabled="!discountCode"   @click.prevent="checkDiscountCode" class="  col-span-4 font-iranSans hover:scale-105 duration-300 text-center text-xs h-8  block bg-TFL-base1 focus:bg-gray-800 text-TFL-dashText2 text-white font-semibold text-sm rounded-lg p-1 m-1">
                     اعمال کد 
                 
                 </button>
                 <button v-if="!LoadingResponseCode"   class="  col-span-4 font-iranSans hover:scale-105 duration-300 text-center text-xs h-8  block bg-TFL-base1 focus:bg-gray-800 text-TFL-dashText2 text-white font-semibold text-sm rounded-lg p-1 m-1">
                    <div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                 
                 </button>
                 
                 <div   v-if="showFailedCode" class=  "bg-red-600 order-3 bg-opacity-70 text-white text-xs col-span-12 px-2  p-1 m-1  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">       
                 کد تخفیف وارده شده صحیح نمی‌باشد 
             </div>
             <div v-if="showSuccessCode"  class=" bg-green-600  text-white text-xs bg-opacity-70 col-span-12 px-2  p-1 m-1  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">       
               
                 کد تخفیف با موفقیت اعمال شد                  
             </div>
                </div>

                <label class="col-span-12 block text-TFL-dashText1 mt-3 text-right font-iranSans" >
                           مشخصات حساب انتخابی 
                        </label>
                <div class="col-span-12 grid grid-cols-2 bg-TFL-background border border-TFL-background bg-opacity-20 p-2 my-2 text-gray-300 rounded-lg" >
                    <div class="col-span-2 mx-1 my-2 flex justify-between">
                        <p class="text-xs">نوع پلن:</p>
                        <p v-if="this.plan == 'Limited'" class="text-xs">  چالش دارای محدودیت زمانی و تارگت سود {{formatNumber(8)}} و {{formatNumber(4)}} درصدی </p>
                        <p v-if="this.plan == 'NoLimit'" class="text-xs">  چالش بدون محدودیت زمانی و تارگت سود {{formatNumber(8)}} و {{formatNumber(4)}} درصدی </p>
                        <p v-if="this.plan == 'Limited6'" class="text-xs">  چالش دارای محدودیت زمانی و تارگت سود {{formatNumber(6)}} و {{formatNumber(6)}} درصدی </p>
                        <p v-if="this.plan == 'NoLimit6'" class="text-xs">  چالش بدون محدودیت زمانی و تارگت سود {{formatNumber(6)}} و {{formatNumber(6)}} درصدی </p>
                        <p v-if="this.plan == 'Rapid'" class="text-xs"> مستقیم    </p>
                        <p v-if="this.plan == 'Multiple'" class="text-xs"> 5X    </p>

                    </div>
                    <div class="col-span-2 mx-1 my-2 flex justify-between">
                        <p class="text-xs">بالانس حساب:</p>
                        <p v-if="this.deposit == 'K1'" class="text-xs">{{formatNumber(1000)}} </p>
                        <p v-if="this.deposit == 'K5'" class="text-xs">{{formatNumber(5000)}} </p>
                        <p v-if="this.deposit == 'K10'" class="text-xs">{{formatNumber(10000)}} </p>
                        <p v-if="this.deposit == 'K25'" class="text-xs">{{formatNumber(25000)}} </p>
                        <p v-if="this.deposit == 'K50'" class="text-xs">{{formatNumber(50000)}} </p>
                        <p v-if="this.deposit == 'K100'" class="text-xs">{{formatNumber(100000)}} </p>

                    </div>
                    <div class="col-span-2 mx-1 my-2 flex justify-between">
                        <p class="text-xs">بروکر:</p>
                        <p  class="text-xs">{{ this.broker }}</p>
                        

                    </div>
                    <div class="col-span-2 mx-1 my-2 flex justify-between">
                        <p class="text-xs">سرور:</p>
                        <p  class="text-xs">{{ this.serverName }}</p>
                        

                    </div>
                    <div class="col-span-2 mx-1 my-2 flex justify-between">
                        <p class="text-xs">پلتفرم:</p>
                        <p  class="text-xs">تمامی حساب‌ها بر روی متاتریدر{{formatNumber(4)}} ارائه می‌شوند.</p>
                        

                    </div>
                </div>
                <div class="flex justify-between col-span-12 p-4 bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-purple-200 via-purple-400 to-TFL-base2 rounded-lg text-white">
                    <div>مبلغ نهایی</div>
                    <div v-if="discountResponse && this.paymentType == 'Crypto'" class="mx-2 justify-center">
                        {{ formatNumber(this.discountResponse) }} $ 
                    </div>
                    <div v-if="discountResponse && this.paymentType == 'Rial'" class="mx-2 justify-center">
                        {{ formatNumber(this.discountResponse) }} تومان 
                    </div>
                    <div v-if="discountResponse && this.paymentType == 'Rial2'" class="mx-2 justify-center">
                        {{ formatNumber(this.discountResponse) }} تومان 
                    </div>
                    <div v-if="!discountResponse && this.paymentType == 'Crypto'" class="mx-2 justify-center">
                          {{ formatNumber(this.price) }} $ 
                    </div>
                    <div v-if="!discountResponse && this.paymentType != 'Crypto'" class="mx-2 justify-center">
                          {{ formatNumber(this.price) }} تومان 
                    </div>
                </div>
                <div class="flex items-center space-x-4 my-4  col-span-12  align-middle text-gray-500 ">
                    <div class="flex items-center mx-2">
                        <input id="default-checkbox" type="checkbox" v-model="isChecked" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
    
                    </div>
                    <div class="text-gray-300">

                        <a href="/Rules" target="_blank" class="text-blue-700">قوانین</a> را مطالعه کرده و با آن موافقم
                    </div>
                </div>
                 <button v-if="response"  @click.prevent="created" :disabled="!isChecked || (serverName == null)" class=" col-span-12 font-iranSans hover:scale-105 duration-300 text-center order-last  block  bg-gradient-to-r from-indigo-500 to-TFL-base1 text-white text-TFL-dashText2 text-white font-semibold text-sm rounded-lg px-1 py-1 my-2">
                    پرداخت 
                
                </button>
                <button  v-if="!response"  @click.prevent="created" :disabled="!isChecked " class="  col-span-12 font-iranSans hover:scale-105 duration-300 text-center order-last  block  bg-gradient-to-r from-indigo-500 to-TFL-base1 text-white text-TFL-dashText2 text-white font-semibold text-sm rounded-lg px-1 py-1 my-2">
                    <div role="status justify-self-center">
                        <svg aria-hidden="true" class="inline w-5 h-5  text-gray-300 animate-spin  fill-white " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div>
                
                </button> 
                <!-- <div    class=  "bg-TFL-red1 order-3  text-white text-xs col-span-12 px-2  p-1 m-1  shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">     
                                به علت به‌روز رسانی تا اطلاع ثانوی امکان پرداخت وجود ندارد.
                              </div> -->

                
            
                

            </div>
            
            
            <div class="   h-[40rem] lg:col-span-7 col-span-12 row-span-3 grid grid-cols-12 order-2 text-sm bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
                <p class="text-TFL-dashText1"> قوانین</p>
                <!-- <div class="col-span-12  grid grid-cols-12 mx-auto h-6" >
                        <button @click="rulesButtonClick('General')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.rules !== 'General','bg-TFL-base1  text-white': this.rules === 'General' }" class="col-span-4  items-center justify-center py-2 px-4 m-1 rounded-lg">قوانین عمومی </button>
                        <button @click="rulesButtonClick('twoSteps')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.rules !== 'twoSteps','bg-TFL-base1  text-white': this.rules === 'twoSteps' }" class="col-span-4 py-2 px-4 m-1 rounded-lg">قوانین چالش دو مرحله‌ای</button>
                        <button @click="rulesButtonClick('Rapid')" :class="{ 'bg-gray-600 bg-opacity-30 text-TFL-dashText2': this.rules !== 'Rapid','bg-TFL-base1  text-white': this.rules === 'Rapid' }" class="col-span-4 py-2 px-4 m-1 rounded-lg">قوانین پلن مستقیم</button>
                        
                        
                    </div> -->
                <div class=" overflow-auto col-span-12">
                <div class="relative col-span-12 w-full  text-center my-50  text-sm  rounded-3xl   max-w-screen-xl p-2 my-4">
            <div>
                <p class="text-TFL-dashText1"> قوانین عمومی</p>
                <div  class="bg-TFL-background">

<div  v-for="(item, index) in GeneralRules" :key="index" class="my-2">
  <h2 class="accordion-header">
    <button
      class=" flex items-center justify-between w-full px-4 py-1 rounded-l-lg text-left text-sm bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "
      @click="toggleAccordion(index)"
    >
      <span>{{ item.title }}</span>
      <svg
        class="accordion-icon w-4 h-4 ml-2 text-gray-500 transition-transform duration-200 transform"
        :class="{ 'rotate-180': activeIndex === index }"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M19 9l-7 7-7-7"></path>
      </svg>
    </button>
  </h2>
  <transition >

      <div  v-if="activeIndex === index" >
        <div class="border-r-4 px-10 py-1 my-4 border-TFL-base1 bg-gray-600 bg-opacity-30 text-right text-gray-300 ">
          <!-- Use v-html directive to render HTML content -->
          <div class="text-xs font-medium line-height "  v-html="formatNumber(item.content)"></div>
        </div>
      </div>
  </transition>
</div>
</div>
<p class="text-TFL-dashText1"> قوانین چالش دو مرحله‌ای</p>
<div class="bg-TFL-background">

<div  v-for="(item, index) in twoStepsRules" :key="index" class="my-2">
  <h2 class="accordion-header">
    <button
      class=" flex items-center justify-between w-full px-4 py-1 rounded-l-lg text-left text-sm bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "
      @click="toggleAccordion(index)"
    >
      <span>{{ item.title }}</span>
      <svg
        class="accordion-icon w-4 h-4 ml-2 text-gray-500 transition-transform duration-200 transform"
        :class="{ 'rotate-180': activeIndex === index }"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M19 9l-7 7-7-7"></path>
      </svg>
    </button>
  </h2>
  <transition >

      <div  v-if="activeIndex === index" >
        <div class="border-r-4 px-10 py-1 my-4 border-TFL-base1 bg-gray-600 bg-opacity-30 text-right text-gray-300 ">
          <!-- Use v-html directive to render HTML content -->
          <div class="text-xs font-medium line-height "  v-html="formatNumber(item.content)"></div>
        </div>
      </div>
  </transition>
</div>
</div>
<p class="text-TFL-dashText1"> قوانین پلن مستقیم</p>
<div  class="bg-TFL-background">

<div  v-for="(item, index) in RapidRules" :key="index" class="my-2">
  <h2 class="accordion-header">
    <button
      class=" flex items-center justify-between w-full px-4 py-1 rounded-l-lg text-left text-sm bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "
      @click="toggleAccordion(index)"
    >
      <span>{{ item.title }}</span>
      <svg
        class="accordion-icon w-4 h-4 ml-2 text-gray-500 transition-transform duration-200 transform"
        :class="{ 'rotate-180': activeIndex === index }"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M19 9l-7 7-7-7"></path>
      </svg>
    </button>
  </h2>
  <transition >

      <div  v-if="activeIndex === index" >
        <div class="border-r-4 px-10 py-1 my-4 border-TFL-base1 bg-gray-600 bg-opacity-30 text-right text-gray-300 ">
          <!-- Use v-html directive to render HTML content -->
          <div class="text-xs font-medium line-height "  v-html="formatNumber(item.content)"></div>
        </div>
      </div>
  </transition>
</div>
</div>
<p class="text-TFL-dashText1"> قوانین پلن 5X</p>
<div  class="bg-TFL-background">

<div  v-for="(item, index) in MultipleRules" :key="index" class="my-2">
  <h2 class="accordion-header">
    <button
      class=" flex items-center justify-between w-full px-4 py-1 rounded-l-lg text-left text-sm bg-gray-700 bg-opacity-50 hover:bg-gray-600 font-medium text-TFL-dashText2 border-r-4 border-TFL-base2    "
      @click="toggleAccordion(index)"
    >
      <span>{{ item.title }}</span>
      <svg
        class="accordion-icon w-4 h-4 ml-2 text-gray-500 transition-transform duration-200 transform"
        :class="{ 'rotate-180': activeIndex === index }"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M19 9l-7 7-7-7"></path>
      </svg>
    </button>
  </h2>
  <transition >

      <div  v-if="activeIndex === index" >
        <div class="border-r-4 px-10 py-1 my-4 border-TFL-base1 bg-gray-600 bg-opacity-30 text-right text-gray-300 ">
          <!-- Use v-html directive to render HTML content -->
          <div class="text-xs font-medium line-height "  v-html="formatNumber(item.content)"></div>
        </div>
      </div>
  </transition>
</div>
</div>
  </div>
    </div>
    </div>

            </div>
            <div v-if="maxAccountLimitError" class="bg-red-600 order-3 bg-opacity-80 col-span-12 border-2 border-red-700 p-2 m-2 mr-4 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
              <p  class="text-white text-sm">کاربر گرامی شما به سقف مجاز حساب فعال رسیده‌اید</p>
              

            </div>
            
                   
        
        
        
        
            <div class=" col-span-12  order-6 overflow-x-auto mr-4  bg-TFL-cardDashboard  p-4 m-2 shadow-[rgba(132,_155,_255,_0.24)_0px_3px_8px] rounded-lg">
                <p class="mb-4 text-TFL-dashText1">تاریخچه سفارشات</p>
                <table class="w-full  text-xs xl:text-sm text-right text-TFL-dashText2 rounded-lg  table-auto">
                    <thead  class=" text-center text-TFL-dashText2 uppercase bg-TFL-card1 rounded rounded-lg ">
                      <tr>
                         <th scope="col" class="px-6 py-3 font-iranSans">#</th>
                         <th scope="col" class="px-6 py-3 font-iranSans">شماره سفارش</th>
                         <th scope="col" class="px-6 py-3 font-iranSans">تاریخ</th> 
                         <th scope="col" class="px-6 py-3 font-iranSans">نوع پلن </th> 
                         <th scope="col" class="px-6 py-3 font-iranSans">نوع حساب </th> 
                         <th scope="col" class="px-6 py-3 font-iranSans">بروکر</th> 
                         <th scope="col" class="px-6 py-3 font-iranSans">وضعیت</th> 
                          
                         
                         <th scope="col" class="px-6 py-3 font-iranSans">توضیحات</th> 
                         
                      </tr>
                    </thead> 
                    <tbody v-if="historyLoaded"  class="bg-TFL-cardDashboard text-center  ">
                        <tr v-for="(payment, index) in payment" :key="index" class="text-gray-300  hover:bg-gray-600 bg-opacity-30">
                            <td class="px-6 py-2">{{ formatNumber(index + 1) }}</td>
                            <td  class="px-6 py-2">{{ formatNumber(payment.id).replace(/,/g,'') }}</td>
                            <td class="px-6 py-2">{{formatNumber( payment.createDateTime.replace("T", " at ").replace("+00:00", "").substring(0, 10) )}}</td>
                            <td v-if="payment.plan=='Limited'" class="px-6 py-2">دارای محدودیت زمانی</td>
                            <td v-if="payment.plan=='NoLimit'" class="px-6 py-2">بدون محدودیت زمانی</td>
                            <td v-if="payment.plan=='Limited6'" class="px-6 py-2">دارای محدودیت زمانی</td>
                            <td v-if="payment.plan=='NoLimit6'" class="px-6 py-2">بدون محدودیت زمانی</td>
                            <td v-if="payment.plan=='Rapid'" class="px-6 py-2">مستقیم</td>
                            <td v-if="payment.deposit == 'K1'" class="px-6 py-2">{{ formatNumber(1000).replace(/,/g,'') }}</td>
                            <td v-if="payment.deposit == 'K5'" class="px-6 py-2">{{ formatNumber(5000).replace(/,/g,'') }}</td>
                            <td v-if="payment.deposit == 'K10'" class="px-6 py-2">{{ formatNumber(10000).replace(/,/g,'') }}</td>
                            <td class="px-6 py-2">{{ payment.broker }}</td>
                            <td class="px-6 py-2">{{ payment.paymentStatus }}</td>
                            
                            
                            <td v-if="payment.paymentStatus == 'Finished'" class="px-2 py-1"><router-link to="/Dashboard/DAccountList"  type="button"
                                class="lg:w-2/3 w-full inline-block text-center rounded bg-TFL-base1 px-3 pb-1 pt-1.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#54b4d3] transition duration-150 ease-in-out hover:bg-TFL-base1 hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)] focus:bg-TFL-base1 focus:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)] focus:outline-none focus:ring-0 active:bg-TFL-base1-700 active:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.3),0_4px_18px_0_rgba(84,180,211,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(84,180,211,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(84,180,211,0.2),0_4px_18px_0_rgba(84,180,211,0.1)] ">
                                اطلاعات حساب
                            </router-link>
                        </td>
                        <td v-if="payment.paymentStatus != 'Finished'" class="px-2 py-1">
                        </td>
                        
                        
                    </tr>
                </tbody>
            </table>
            <div v-if="!historyLoaded"  class="bg-white text-center border border-rounded-lg ">
                <tr class="text-gray-300  hover:bg-gray-600 bg-opacity-30">
                    <td class="px-6 py-2 text-sm " >در حال دریافت اطلاعات...</td>
                </tr>
            </div> 
            <div v-if="payment.length == 0 && historyLoaded" class="font-iranSans text-sm   space-x-4 my-2   px-2 py-2 text-right rounded-lg bg-blue-700 text-blue-800 bg-opacity-10  border-2 " >
               کاربر گرامی، در حال حاضر شما هیچ سفارشی ندارید.
            </div> 
        
        
            </div>
    </div>
    
</template>

<script>
import axios from 'axios';
import { digitsEnToFa } from 'persian-tools';
import URL from "../URL";
import Rules from '../Rules';



export default {
    
    data(){
        return {
           
            broker: 'Roboforex',
            platform: 'MT4',
            serverName: '',
            leverage: 100,
            deposit: 'K5',
            serverNameList :[],
            plan: 'Limited',
            historyLoaded: false,
            paymentType: 'Crypto',

            payment:[],

            isChecked: false,

            discountCode: '',
            discountResponse: '',
            confirmedDiscountCode: '',

            showSuccessCode: false,
            showFailedCode: false,

            response: true,
            LoadingResponseCode: true,
            loadToken: false,
            
            price: '',
            maxAccountLimitError: false,
            
      activeIndex: null,
      
      rules: 'General',
      GeneralRules: Rules.GeneralRules,
      twoStepsRules: Rules.twoStepsRules,
      RapidRules: Rules.RapidRules,
      MultipleRules: Rules.MultipleRules,

           

            

        }
    },
    mounted(){
        this.fetchPayment();
        this.getServer();
        this.checkPrice();
        this.checkToken();


    },
    watch: {
        broker() {
      // Call the API whenever the variable changes
      this.getServer();
    },
    // serverName(index){
    //     this.serverNameChange(index);
    // }
  },
    methods: {
        rulesButtonClick(value){
            this.rules = value;
            
            
        },
        formatNumber(number) {
      const formattedNumber = number.toLocaleString('en-US');
      return digitsEnToFa(formattedNumber);
    },
    checkToken(){
         const url = URL.baseUrl + `users/whoami`
         axios.get(url,{
        headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
    })
        .then(response => {
         
         if(response.data.phoneNumberConfirmed == false){
           this.notAllowRial = true

         }
         if(response.data.phoneNumberConfirmed == true){
             this.notAllowRial = false;
           
         }
         this.loadToken = true;
          
          
        })
        .catch(error => {
          
          window.location.href = '/login';


        });
      },
    toggleAccordion(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    },
    checkDiscountCode(){
        this.LoadingResponseCode = !this.LoadingResponseCode;
        const url = URL.baseUrl + `Meta/discount?code=${this.discountCode}&deposit=${this.deposit}&plan=${this.plan}&paymentType=${this.paymentType}`;
        axios.get(url,
       
            {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
            
        
        )
        .then(response => {
            
            this.discountResponse = response.data;
            this.showFailedCode = false;
            this.showSuccessCode = true;
            this.confirmedDiscountCode = this.discountCode;
            this.LoadingResponseCode = !this.LoadingResponseCode;
           
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            this.discountResponse = '';
            this.checkPrice();
            this.showSuccessCode = false;
            this.showFailedCode = true;
            this.confirmedDiscountCode = null;
            this.LoadingResponseCode = !this.LoadingResponseCode;
            });

    },
    checkPrice(){
        if (this.plan !== 'Multiple'){

            if (this.plan !== 'Rapid'   ){
        if( this.deposit == 'K50' || this.deposit == 'K100' ){
            this.deposit = 'K1';
        }
      }
        }
        if ( this.plan === 'Multiple' ){
    if( this.deposit !== 'K50' && this.deposit !== 'K100' ){
        this.deposit = 'K50';
    }
  }
        const url = URL.baseUrl + `Meta/discount?deposit=${this.deposit}&plan=${this.plan}&paymentType=${this.paymentType}`;
        axios.get(url,
       
            {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
            
        
        )
        .then(response => {
            
            this.price = response.data;

           
            
           
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            
            });

    },
        isCheckedClicked(){
            this.isChecked = !this.isChecked
        },
        depositButtonClick(value){
            this.deposit = value;
            
            if(this.discountResponse){
                this.checkDiscountCode();
            };
            if(!this.discountResponse){
                
                this.checkPrice();
            }
        },
        choosePlan(value){
           this.plan = value;
           if(this.discountResponse){
                this.checkDiscountCode();
            };
            if(!this.discountResponse){
                
                this.checkPrice();
            }   
        },
        brokerButtonClick(value){
            this.broker = value;
        },
        choosePayment(value){
            this.paymentType = value;
            if(this.discountResponse){
                this.checkDiscountCode();
            };
            if(!this.discountResponse){
                
                this.checkPrice();
            }
        },
        leverageButtonClick(value){
            this.leverage = value;
        },
        platformButtonClick(value){
            this.platform = value;
        },
        serverNameChange(index){
            this.serverName = this.serverNameList[index].name;
        },
        fetchPayment(){
            const url = URL.baseUrl + `Meta/payments`;
            axios.get(url,
       
            {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
            
        
        )
        .then(response => {
            
            this.payment = response.data;
            this.historyLoaded = true;
           
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            this.historyLoaded = true;
            
            
            });

        },


        
        getServer() {
  // POST request using axios with error handling
  
        this.serverNameList = '';
        this.serverName = null;
        const url = URL.baseUrl + `Meta/Broker/${this.broker}`;
        axios.get(url,
       
            {
            headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        }
            
        
        )
        .then(response => {
            // Handle successful response here
            
            this.serverNameList = response.data;
            
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            
            });
},
        created() {
            this.response = !this.response
  // POST request using axios with error handling
        const url = URL.baseUrl + `Meta/account`;
        axios.post(url,
        { 
           
            
            broker: this.broker,
            platform: this.platform,
            serverName: this.serverName,
            leverage: this.leverage,
            deposit: this.deposit,
            discountCode: this.confirmedDiscountCode,
            plan: this.plan,
            paymentType: this.paymentType,
            },
            {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
            
        
        )
        .then(response => {
            // Handle successful response here
           
            this.response = !this.response;
            window.location.href = response.data.invoiceUrl;
        })
            

            .catch(error => {
            this.errorMessage = error.message;
            
            this.response = !this.response;
            if (error.response && error.response.data && error.response.data.code === 11060) {
                this.maxAccountLimitError = true;
    }
            });
},
        freeCreated() {
  // POST request using axios with error handling
        const url = URL.baseUrl + `Meta/account/test`;
        axios.post(url,
        { 
           
            
            broker: this.broker,
            platform: this.platform,
            serverName: this.serverName,
            leverage: this.leverage,
            deposit: this.deposit
            },
            {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  }
            
        
        )
       
            

            .catch(error => {
            this.errorMessage = error.message;
            
            });
}
    }
}
</script>
